import styled from "@emotion/styled";
import {
  BigWheelStrikesContainer
} from "assets/images";

export const Container = styled.div`
  display: flex;
  width: 440px;
  height: 90px;
  background-image: url(${BigWheelStrikesContainer});
  background-size: 100% 100%;
  background-position-x: -6px;
  background-repeat: no-repeat;
  z-index: 2;
  margin-top: -40px;
  padding-top: 12px;
  padding-left: 174px;
`

export const Hole = styled.img`
  width: 76px;
  height: 86px;
  visibility: hidden;

  &.item-0 {
    margin-top: -26px;
    margin-left: 2px;
  }

  &.item-1 {
    margin-top: -22px;
    margin-left: 2px;
  }

  &.item-2 {
    margin-top: -22px;
    margin-left: -1px;
  }

  ${prop => prop.visible ? `
    visibility: visible;
  `: ''};
`